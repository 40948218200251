<template>
  <div>
    <el-row
      type="flex"
      justify="end"
    >
      <div
        @click="collapse = !collapse"
        class="mr-2"
      >
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Filtrar Relato"
        >
          <i
            slot="reference"
            :class="collapse? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          ></i></el-popover>
      </div>
      <div @click="$router.push({name: 'cadastrar-relato-supervisor'})">
        <el-popover
          class="btn btn-micro"
          placement="top-start"
          width="100"
          trigger="hover"
          content="Cadastrar Relato"
        >
          <i
            slot="reference"
            class="glyphicon glyphicon-plus-sign"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <br>
    <vuestic-widget v-show="collapse">
      <div class="container">
        <div class="row">
          <div class="col">
            <fieldset>
              <vuestic-radio-button
                option=""
                v-model="situacao"
                label="Todos"
              />
            </fieldset>
          </div>
          <div class="col">
            <vuestic-radio-button
              option="1"
              v-model="situacao"
              label="Pendente"
            />
          </div>
          <div class="col">
            <vuestic-radio-button
              option="2"
              v-model="situacao"
              label="Relato Cancelado"
            />
          </div>
          <div class="col">
            <vuestic-radio-button
              option="3"
              v-model="situacao"
              label="Relato Tratado"
            />
          </div>

        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <input
                v-model="pesquisaAvancada.tipoRelato"
                id="Tipo-de-relato"
                required
              >
              <label
                class="control-label"
                for="Tipo-de-relato"
              > Tipo de Relato</label>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <input
                v-model="pesquisaAvancada.grupoRelato"
                id="grupo-de-relato"
                required
              >
              <label
                class="control-label"
                for="grupo-de-relato"
              > Grupo de Relato</label>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <input
                v-model="pesquisaAvancada.funcionario"
                id="funcionario"
                required
              >
              <label
                class="control-label"
                for="funcionario"
              > Funcionário</label>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <input
                v-model="pesquisaAvancada.areaRelatado"
                id="area-relatada"
                required
              >
              <label
                class="control-label"
                for="area-relatada"
              > Área Relatada</label>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <input
                v-model="pesquisaAvancada.areaRelatante"
                id="area-do-relatante"
                required
              >
              <label
                class="control-label"
                for="area-do-relatante"
              > Área do Relatante</label>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <vuestic-date-picker
                id="date-picker-range"
                :config="{
                    mode: 'range',
                    dateFormat: 'd/m/Y',
                    }"
                v-model="pesquisaAvancada.dataCadastro"
              />
              <i
                v-if="pesquisaAvancada.dataCadastro"
                class="fa fa-times icon-right input-icon pointer"
                @click="clearDate()"
              >
              </i>
              <label
                class="control-label"
                for="date-picker-basic"
              >
                Data de cadastro
              </label>
            </div>
          </div>

        </div>

        <!---->

        <form>
          <el-row
            type="flex"
            class="row-bg"
            justify="end"
          >
            <el-button
              class="ml-3"
              type="primary"
              icon="el-icon-search"
              @click="getRelatos()"
            >
              Filtrar
            </el-button>
            <exportar-relatos
              class="ml-3"
              :relatos="relatos"
              :urlExportacao="urlExportacao"
            />
            <el-button
              class="ml-3"
              type="danger"
              icon="el-icon-close"
              @click="limparPesquisa"
            >
              Limpar
            </el-button>
          </el-row>
        </form>

        <!---->

      </div>
    </vuestic-widget>

    <vuestic-widget>

      <data-tables-server
        v-loading="loading"
        :pagination-props="{ pageSizes: [10, 20, 50, 100] }"
        :total="count"
        style="width: 100%;"
        @query-change="getRelatos"
        :data="relatos"
        @row-dblclick="abrirRelato"
      >
        <el-table-column
          label="Status"
          prop="situacao"
          width="100"
        >
          <template
            slot-scope="row"
            style="width: 100%;"
          >
            <div
              :class="status.cor[row.row.situacao]"
              class="badge"
            >{{status.nome[row.row.situacao]}}</div>
          </template>
        </el-table-column>
        <el-table-column
          label="Anexo"
          prop="imagem"
          width="70"
        >
          <template
            slot-scope="row"
            style="width: 100%;"
          >
            <i
              v-if="row.row.imagem"
              class="el-icon-picture style-icon-img"
              @click.stop="abrirImagem(row.row.imagem)"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="180"
          label="Relatante"
          sortable="custom"
          prop="nome_do_relatante"
        />
        <el-table-column
          width="180"
          label="Data e hora"
          sortable="custom"
          prop="nova_hora"
        />
        <el-table-column
          width="180"
          label="local"
          sortable="custom"
          prop="local"
        />
        <el-table-column
          width="180"
          label="placa"
          sortable="custom"
          prop="placa"
        />
        <el-table-column
          width="180"
          label="funcionário"
          sortable="custom"
          prop="funcionario"
        />
        <el-table-column
          width="180"
          label="Função"
          sortable="custom"
          prop="funcao"
        />
        <el-table-column
          width="180"
          label="item"
          sortable="custom"
          prop="item_nome"
        />
        <el-table-column
          width="180"
          label="Área do relatante"
          sortable="custom"
          prop="area_relatante_nome"
        />
        <el-table-column
          width="180"
          label="Área do relatado"
          sortable="custom"
          prop="area_relatado_nome"
        />
      </data-tables-server>
    </vuestic-widget>
    <model-visualizar-relato
      ref="MVR"
      :relato="relatoSelecionado"
      :areas="areas"
      :itemRelato="listaRelatos.item"
      :grupoRelato="listaRelatos.grupo"
      :tiposRelatos="tiposRelatos"
      v-on:editado="getRelatos()"
    />
    <modal-visualizar-imagem
      ref="MVI"
      :link="linkImage"
    />
  </div>
</template>

<script>
import moment from 'moment';
import modelVisualizarRelato from '../form/modalVisualizarRelato.vue';
import exportarRelatos from '../../my-components/export/relatos.vue';
import modalVisualizarImagem from '../form/modalVisualizarImagem.vue';

export default {
  components: { modelVisualizarRelato, exportarRelatos, modalVisualizarImagem },
  data() {
    return {
      urlExportacao: '',
      listaRelatos: { grupo: [], item: [] },
      tiposRelatos: [],
      collapse: false,
      status: {
        nome: {
          1: 'Pendente',
          2: 'Cancelado',
          3: 'Tratado',
        },
        cor: {
          1: 'badge-danger',
          2: 'badge-dark',
          3: 'badge-success',
        },

      },
      loading: true,
      relatos: [],
      idEmpresa: null,
      count: null,
      linkImage: '',
      pesquisaAvancada: {
        tipoRelato: '',
        grupoRelato: '',
        funcionario: '',
        areaRelatado: '',
        areaRelatante: '',
        dataCadastro: '',
      },
      areas: [],
      situacao: '',
      relatoSelecionado: {},
    };
  },
  created() {
    this.idEmpresa = localStorage.empresaSelecionada;
    this.getAreas();
    this.getEmpresa();
  },
  methods: {
    getRelatos(paginaAtual) {
      this.loading = true;
      this.$axios.get(`/api/relato-por-empresa/${this.idEmpresa}/${this.endPointPesquisa(paginaAtual)}`).then((res) => {
        this.urlExportacao = res.data.results.length > 0
          ? `/api/relato-list/${this.endPointPesquisa(paginaAtual)}empresa=${res.data.results[0].empresa_nome}`
          : '';
        this.count = res.data.count;
        this.relatos = res.data.results.map(this.prepareRelatosForUser);
        this.loading = false;
      });
    },
    prepareRelatosForUser(relato) {
      const novoRelato = relato;
      const area = this.areas.find(a => a.id === novoRelato.area_do_relatante);
      const nome = area ? area.nome : '';
      novoRelato.area_relatante_nome = nome;
      novoRelato.nova_hora = this.dataFormatBr(relato.hora);
      return novoRelato;
    },
    dataFormatBr(data) {
      return data ? moment(data).format('DD/MM/YYYY - HH:mm') : '';
    },
    abrirImagem(link) {
      this.linkImage = link;
      this.$refs.MVI.open();
    },
    endPointPesquisa(paginaAtual) {
      const { pesquisaAvancada } = this;
      let pesquisa = '';
      let pagina = '';
      const orderBy = paginaAtual ? this.sort(paginaAtual.sort) : '';

      if (paginaAtual) {
        pagina = `page=${paginaAtual.page}&paginas=${paginaAtual.pageSize}&`;
      }

      if (this.situacao) {
        pesquisa += `situacao=${this.situacao}&`;
      }
      if (pesquisaAvancada.dataCadastro) {
        const dataDividida = pesquisaAvancada.dataCadastro.replace(/\//g, '%2F').split(' to ');
        pesquisa = `tipo=cadastro&min_date=${dataDividida[0]}&max_date=${dataDividida[1]}&`;
      }
      if (pesquisaAvancada.areaRelatado) {
        pesquisa += `area-relatado=${pesquisaAvancada.areaRelatado}&`;
      }
      if (pesquisaAvancada.areaRelatante) {
        pesquisa += `area-relatante=${pesquisaAvancada.areaRelatante}&`;
      }
      if (pesquisaAvancada.grupoRelato) {
        pesquisa += `grupo=${pesquisaAvancada.grupoRelato}&`;
      }
      if (pesquisaAvancada.tipoRelato) {
        pesquisa += `tipo-relato=${pesquisaAvancada.tipoRelato}&`;
      }
      if (pesquisaAvancada.funcionario) {
        pesquisa += `funcionario=${pesquisaAvancada.funcionario}&`;
      }
      return `?${pesquisa}${pagina}${orderBy}`;
    },

    sort(orderBy) {
      let newOrder = '';
      if (orderBy.order === 'descending') {
        newOrder = '-';
      }
      if (orderBy.prop === 'item_nome') {
        newOrder += 'item__nome';
      } else if (orderBy.prop === 'area_relatante_nome') {
        newOrder += 'area_do_relatante__nome';
      } else if (orderBy.prop === 'area_relatado_nome') {
        newOrder += 'area_do_relatado__nome';
      } else {
        newOrder += orderBy.prop;
      }
      return `ordering=${newOrder}&`;
    },
    limparPesquisa() {
      this.pesquisaAvancada = {
        tipoRelato: '',
        grupoRelato: '',
        funcionario: '',
        areaRelatado: '',
        areaRelatante: '',
      };
      this.situacao = '';
      this.getRelatos();
    },
    getAreas() {
      this.$axios.get('/api/area-list/').then((res) => {
        this.areas = res.data;
        this.getRelatos();
      });
    },
    abrirRelato(row) {
      this.relatoSelecionado = row;
      this.$refs.MVR.open();
    },
    getEmpresa() {
      this.$axios.get(`/api/empresa-list/${this.idEmpresa}/`).then((res) => {
        this.getTipo(res.data.modelo);
        this.getGrupo();
        this.getItem();
      });
    },
    getTipo(id) {
      this.$axios.get('api/tipo-de-relato-list/').then((res) => {
        this.tiposRelatos = res.data.filter(t => t.modelo === id);
      });
    },
    getGrupo() {
      this.$axios.get('/api/grupo-list/').then((res) => {
        this.listaRelatos.grupo = res.data;
      });
    },
    getItem() {
      this.$axios.get('/api/item-list/').then((res) => {
        this.listaRelatos.item = res.data;
      });
    },
    clearDate() {
      this.pesquisaAvancada.dataCadastro = '';
    },
  },
  watch: {
    situacao() {
      this.getRelatos();
    },
  },
};
</script>

<style>
.style-icon-img {
  font-size: 1.3rem;
  color: #4e7db7;
}
.form-group .input-icon {
  position: absolute;
  top: 0.8rem;
  right: 2px;
  color: #b3b3b3;
}
</style>
